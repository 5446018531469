import { signalStore, withComputed, withState } from "@ngrx/signals";
import { initialScenarioState } from "../scripts/script.types";
import { withEntities } from "@ngrx/signals/entities";
import { withEventTypesMethods } from "./event-types.methods";
import { computed } from "@angular/core";
import { ScenarioEventTypeGroup, ScenarioEventTypeItem } from "../../api/models";
import { SearchSelectGroup } from "../../ui/form/search-select/SearchSelectGroup.class";
import { LanguageService } from "../../translate/language.service";
import { SelectOption } from "../../ui/form/search-select/SelectOption.class";
import { GroupSelectOptionArray } from "../../ui/form/search-select/search-select.group.model";

export const EventTypesStore = signalStore(
    { providedIn: 'root', protectedState: false},
    withState(initialScenarioState),
    withEntities<ScenarioEventTypeGroup>(),
    withEventTypesMethods(),
    withComputed((store) => ({
        getGroupSelectOptionArray: computed(() => {
            let groups: ScenarioEventTypeGroup[] = store.entities();
            let options: SearchSelectGroup[] = groups.map((group: ScenarioEventTypeGroup, index: number) => {
                return new SearchSelectGroup({ 
                    id: group.id, 
                    name: LanguageService.translate(`context.names.${group.id}`),
                    sort: index,
                    value: (group.options || []).map((value: ScenarioEventTypeItem) => {
                        return new SelectOption({
                            id: value.id,
                            label: LanguageService.translate(value.label),
                            value: value.id,
                            metadata: {
                                schema: (value.variables ? value.variables[0]?.schema : '')
                            }
                        })
                    }) 
                });
            });
            return new GroupSelectOptionArray(options);
        })
    })),
);