import { StandartErrors } from "../../../helpers/StandartErrors.class";
import { SelectOption } from "./SelectOption.class";
import { TSearchSelectGroup } from "./search-select.types";
import {HelperFunctions} from "../../../helpers/HelperFunctions";

export class SearchSelectGroup {
    private id: string;
    private name: string;
    private sort: number;
    private value: SelectOption[];

    constructor(data: TSearchSelectGroup) {
        if (data?.id === undefined || data?.name === undefined || data?.sort === undefined || !Array.isArray(data.value)) {
            StandartErrors.invalidDataType();
        }
        this.id = data.id;
        this.name = data.name;
        this.sort = data.sort;
        this.value = data.value;
    }

    getId(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getById(id: string): SelectOption | undefined {
        return this.getId() === 'hook' ? this.value.find((el) => HelperFunctions.isPascalized(el.id) ? el.id === id : HelperFunctions.pascalize(el.id) === id) : this.value.find((el: SelectOption) => el.id === id);
    }

    getSort(): number {
        return this.sort;
    }

    getValue(): SelectOption[] {
        return this.value;
    }
}
