import { signalStore, withComputed, withState } from "@ngrx/signals";
import { initialScenarioState } from "../scripts/script.types";
import { withEntities } from "@ngrx/signals/entities";
import { withScenarioConstructorDataMethods } from "./scenario-constructor-data.methods";
import { ScenarioFunction, ScenarioVariable } from "../../api/models";
import { computed, inject } from "@angular/core";
import { SelectOption } from "../../ui/form/search-select/SelectOption.class";
import { GroupSelectOptionArray } from "../../ui/form/search-select/search-select.group.model";
import { SearchSelectGroup } from "../../ui/form/search-select/SearchSelectGroup.class";
import { FunctionsStore } from "../functions/functions.store";
import { LanguageService } from "../../translate/language.service";
import { SchemasStore } from "../schemas/schemas.store";


export const ScenarioConstructorDataStore = signalStore(
    { providedIn: 'root', protectedState: false},
    withState(initialScenarioState),
    withEntities<ScenarioVariable>(),
    withScenarioConstructorDataMethods(),
    withComputed((store, schemasStore = inject(SchemasStore), functionsStore = inject(FunctionsStore)) => ({
        selectOptions: computed(() => {
            let functions: ScenarioVariable[] = store.entities();
            return functions.map(func => {
                return new SelectOption({ id: func.id, label: '', value: '' });
            })
        }),
        groupSelectOptionArray: computed(() => {
            let variables: ScenarioVariable[] = store.entities();
            let options: SearchSelectGroup[] = variables.map((func: ScenarioVariable, index: number) => {
                return new SearchSelectGroup({
                    id: func.id,
                    name: LanguageService.translate(func.name),
                    sort: index,
                    value: (schemasStore.entityMap()[func?.schema || '']?.properties || []).map((value: ScenarioVariable) => {
                        return new SelectOption({
                            id: value.id,
                            label: LanguageService.translate(value.name),
                            value: (value.enums || value.schema || '*')
                        })
                    })
                });
            });

            let functions: ScenarioFunction[] = functionsStore.entities();
            let functionsGroups: {[groupName: string]: ScenarioFunction[]} = {};
            functions.forEach((el: ScenarioFunction) => {
                if (!!functionsGroups[LanguageService.translate(el.group_name)]) {
                    functionsGroups[LanguageService.translate(el.group_name)].push(el);
                } else {
                    functionsGroups[LanguageService.translate(el.group_name)] = [el];
                }

            });
            for (let functionsGroupName in functionsGroups) {
                options.push(new SearchSelectGroup({
                    id: 'function',
                    name: functionsGroupName,
                    sort: options.length,
                    value: functionsGroups[functionsGroupName].map((func: ScenarioFunction, index: number) => {
                        return new SelectOption({
                            id: func.id,
                            label: LanguageService.translate(func.name),
                            value: func.id
                        });
                    })
                }));
            }
            return new GroupSelectOptionArray(options);
        })
    })),
);
