type SchemasState = {
    isLoaded: boolean;
    selected_id: string;
    group_id: string;
};

export const initialSchemaState: SchemasState = {
    isLoaded: false,
    selected_id: '',
    group_id: '',
};
