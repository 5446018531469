import { signalStore, type, withComputed, withHooks, withState } from "@ngrx/signals";
import { withEntities } from "@ngrx/signals/entities";
import { withFunctionsMethods } from "./functions.methods";
import { computed } from "@angular/core";
import { SelectOption } from "../../ui/form/search-select/SelectOption.class";
import { ScenarioFunction } from "../../api/models";
import { initialScenarioState } from "../scripts/script.types";


export const FunctionsStore = signalStore(
    { providedIn: 'root', protectedState: false},
    withState(initialScenarioState),
    withEntities<ScenarioFunction>(),
    withFunctionsMethods(),
    withComputed((store) => ({
        selectOptions: computed(() => {
            let functions: ScenarioFunction[] = store.entities();
            return functions.map(func => {
                return new SelectOption({ id: func.id, label: '', value: '' });
            })
        })
    })),
)


